module.exports = {
  siteTitle: 'MCV Sports', // <title>
  manifestName: 'Grayscale',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#313b41',
  manifestThemeColor: '#313b41',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'MCV Sports',
  subHeading: 'To connect Athletes, Agents, Teams, Federations & Academies Worldwide. Provide the highest quality of career representation and management.',

  // social
  socialLinks: [
    // {
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/anubhavsrivastava',
    // },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/company/mcvsports',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/mcv_sports',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://www.facebook.com/MCVSports',
    },
  ],
  email: 'info@mcvsports.pt',
  address: 'Lisbon, Portugal'
  // phone: '000-00000',
  // address: '305 Del Sol, Sunnyvale, CA',
};
